<script>
	import analytics from '$utils/google-analytics';
	import BookingActions from '$lib/BookingActions.svelte';
	import BookingHeader from '$lib/BookingHeader.svelte';
	import BookingStart from '$lib/BookingStart.svelte';
	import Logo from '$lib/Logo.svelte';
	import { goto } from '$app/navigation';
	import { browser } from '$app/env';

	export let translations;
	export let backLink;
	export let backText;
	export let titleText;
	export let nextText;
	export let name;
	export let email;
	export let phone;
	export let phoneCountryCode;
	export let requestedDate;
	export let requestedTime;
	export let customerType;
	export let timeOptions;
	export let serviceOptions;
	export let phoneCodeOptions;
	export let contactLink;
	export let order;
	export let redirect;

	let submitting = false;

	$: disabled = !name || !email || !phone || !requestedDate || !customerType || submitting;

	$: if (order && redirect && browser) {
		const { $id, name, email } = order;

		analytics.identify($id, { name, email });

		analytics.track('order_updated', order);

		goto(redirect);
	}
</script>

<svelte:head>
	<title>{titleText}</title>
</svelte:head>

{#if !redirect}
	<form class="booking-form" method="post" on:submit={() => (submitting = true)}>
		<Logo />

		<BookingHeader {backText} {backLink} {titleText} />

		<BookingStart
			{translations}
			bind:name
			bind:email
			bind:phone
			bind:customerType
			bind:requestedDate
			{phoneCountryCode}
			{requestedTime}
			{timeOptions}
			{serviceOptions}
			{phoneCodeOptions}
			{contactLink}
		/>

		<BookingActions {nextText} {disabled} />
	</form>
{/if}

<style>
	form {
		margin-top: 44px;
	}
</style>
